import { Routes, Route, Navigate } from 'react-router-dom';
import LazyRoute from './components/LazyRoute';
import { ProtectedRoute } from './components/ProtectedRoute';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LazyRoute componentPath="Login" />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <LazyRoute componentPath="Dashboard" />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
}

export default App;

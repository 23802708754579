import { useEffect } from 'react';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { checkAuthStatus } from '../store/authSlice';

export function AuthInitializer({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkAuthStatus());
  }, [dispatch]);

  return <>{children}</>;
} 
import { useAppSelector } from '../hooks/useAppSelector';

function RoutePreloader() {
  const { isLoading } = useAppSelector(state => state.loading);

  if (!isLoading) return null;

  return (
    <div className="min-h-screen flex items-center justify-center bg-white animate-fadeIn">
      <div className="flex flex-col items-center space-y-6">
        <div className="relative">
          <div className="animate-spin rounded-full h-16 w-16 border-4 border-gray-200"></div>
          <div className="absolute top-0 left-0 animate-spin rounded-full h-16 w-16 border-t-4 border-black"></div>
        </div>
        
        <div className="text-gray-600 text-lg font-medium">
          Chargement en cours...
        </div>
      </div>
    </div>
  );
}

export default RoutePreloader; 
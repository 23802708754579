import { Suspense, lazy, useEffect } from 'react'
import RoutePreloader from './RoutePreloader'
import { useAppDispatch } from '../hooks/useAppDispatch';
import { startLoading, finishLoading } from '../store/loadingSlice';

interface LazyRouteProps {
  componentPath: string;
}

function LazyRoute({ componentPath }: LazyRouteProps) {
  const dispatch = useAppDispatch();

  const LazyComponent = lazy(() => {
    return import(`../pages/${componentPath}.tsx`)
      .catch(error => {
        dispatch(finishLoading());
        throw error;
      });
  });
  
  useEffect(() => {
    dispatch(startLoading());
    return () => {
      dispatch(finishLoading());
    };
  }, [dispatch, componentPath]);
  
  return (
    <Suspense fallback={<RoutePreloader />}>
      <LazyComponent />
    </Suspense>
  );
}

export default LazyRoute; 